<template>
	<v-layout column style="position: relative">
		<v-flex
			v-if="loading"
			align-center
			class="w-loading-container"
			column
			fill-height
			justify-center
			style="display: flex; height: 100%; position: absolute; width: 100%; z-index: 2"
		>
			<v-progress-circular color="primary" indeterminate />
		</v-flex>
		<template v-if="!displaySmallLayout">
			<v-flex grow>
				<v-layout row>
					<v-subheader style="width: calc(50% - 0.5px)">{{ $t(`customization.light_mode`) }}</v-subheader>
					<v-divider vertical />
					<v-subheader style="width: calc(50% - 0.5px)">{{ $t(`customization.dark_mode`) }}</v-subheader>
				</v-layout>
			</v-flex>
			<v-divider />
			<v-flex>
				<v-layout row>
					<WhiteLabelThemeForm
						:readonly="readonly"
						style="width: calc(50% - 0.5px)"
						theme="light"
						:validation.sync="isLightThemeValid"
						:value="lightTheme"
						v-on="lightThemeListeners"
						@input="onInput($event, 'light')"
					/>
					<w-divider vertical />
					<WhiteLabelThemeForm :readonly="readonly" style="width: calc(50% - 0.5px)" theme="dark" :validation.sync="isDarkThemeValid" :value="darkTheme" v-on="darkThemeListeners" @input="onInput($event, 'dark')" />
				</v-layout>
			</v-flex>
		</template>
		<template v-else>
			<w-tabs v-model="tab">
				<w-tab>{{ $t('customization.light_mode') }}</w-tab>
				<w-divider vertical />
				<w-tab>{{ $t('customization.dark_mode') }}</w-tab>
			</w-tabs>
			<w-flex scroll-y>
				<v-tabs-items v-model="tab">
					<v-tab-item :key="0">
						<WhiteLabelThemeForm :readonly="readonly" theme="light" :validation.sync="isLightThemeValid" :value="lightTheme" v-on="lightThemeListeners" @input="onInput($event, 'light')" />
					</v-tab-item>
					<v-tab-item :key="1">
						<WhiteLabelThemeForm :readonly="readonly" theme="dark" :validation.sync="isDarkThemeValid" :value="darkTheme" v-on="darkThemeListeners" @input="onInput($event, 'dark')" />
					</v-tab-item>
				</v-tabs-items>
			</w-flex>
		</template>
	</v-layout>
</template>

<script>
export default {
	name: 'WhiteLabelForm',
	components: {
		WhiteLabelThemeForm: () => ({
			component: import('@/components/WhiteLabel/WhiteLabelThemeForm')
		})
	},
	props: {
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		validation: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			darkTheme: {
				theme: 'dark'
			},
			displaySmallLayout: true,
			isDarkThemeValid: false,
			isLightThemeValid: false,
			lightTheme: {
				theme: 'light'
			},
			resizeObserver: null,
			smBreakpoint: 800,
			tab: 0,
			width: 0
		}
	},
	computed: {
		darkThemeListeners: function () {
			const result = {}

			if (this.$listeners.hasOwnProperty('saveDark')) {
				result.save = this.$listeners.saveDark
			}

			return result
		},
		lightThemeListeners: function () {
			const result = {}

			if (this.$listeners.hasOwnProperty('saveLight')) {
				result.save = this.$listeners.saveLight
			}

			return result
		}
	},
	watch: {
		isDarkThemeValid: {
			handler: 'computeValidation'
		},
		isLightThemeValid: {
			handler: 'computeValidation'
		},
		value: {
			deep: true,
			handler: 'copyValue',
			immediate: true
		}
	},
	beforeDestroy: function () {
		this.resizeObserver.disconnect(this.$el)
	},
	created: function () {
		this.resizeObserver = new ResizeObserver(
			function (resizeEntries) {
				const entry = resizeEntries[0]
				const width = entry.contentRect.width

				this.displaySmallLayout = width < this.smBreakpoint

				this.width = width
			}.bind(this)
		)
	},
	mounted: function () {
		this.resizeObserver.observe(this.$el)
	},
	methods: {
		/**
		 * @return {void}
		 */
		computeValidation: function () {
			this.$emit('update:validation', this.isDarkThemeValid && this.isLightThemeValid)
		},
		/**
		 * @return {Promise<void>}
		 */
		copyValue: function () {
			return new Promise(resolve => {
				this.value.forEach(whiteLabel => {
					if (whiteLabel.theme === 'dark') {
						this.darkTheme = whiteLabel
					} else {
						this.lightTheme = whiteLabel
					}
				})

				resolve()
			})
		},
		onInput: function (themeData, theme) {
			this.$emit(
				'input',
				this.value.map(whiteLabel => {
					if (whiteLabel.theme !== theme) {
						return whiteLabel
					}

					Object.assign(whiteLabel, themeData)

					return whiteLabel
				})
			)
		}
	}
}
</script>
